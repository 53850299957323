import Swal from 'sweetalert2';

export const successAlert = (title) => {
  Swal.fire({
    icon: 'success',
    title: title,
    showConfirmButton: false,
    timer: 1500
  });
}

export const failureAlert = (title, message) => {
  Swal.fire({
    icon: 'error',
    title: title,
    text: message,
  })
}

export const openAIAlert = (setSelectedOption) => {
  Swal.fire({
    title: 'You have a new properties generated by AI',
    text: "Do you want to check it?",
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes'
  }).then((result) => {
    if (result.isConfirmed) {
      setSelectedOption('newProperties');
    }
  })
}