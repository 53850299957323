const excludePageViewEvent = false; //Change this if you want to include page event to your segment protocols
const excludeIdentifyEvent = true; //Change this if you want to include identify event to your segment protocols

export const segmentConverter = (figmaData, spreadsheetProps) => {
  for (const figmaItem of figmaData) {
    delete figmaItem.eventPath;
    const prop = JSON.parse(spreadsheetProps).find(
      (item) => item["Property Name"] === figmaItem.property
    );

    if (prop) {
      const propType = prop["Property Data Type"];
      figmaItem.type = propType.includes("null") ? "string" : propType;
      figmaItem.propertyDescription = prop["Property Description"];
      figmaItem.required = prop.Required;
    }
  }

  const segmentData = figmaData.filter((item, index, self) => {
    if (excludePageViewEvent && /\s+viewed/gi.test(item.eventName))
      return false;

    const duplicateIndex = self.findIndex(
      (otherItem) =>
        otherItem.eventName === item.eventName &&
        otherItem.eventDescription === item.eventDescription &&
        otherItem.category === item.category &&
        otherItem.property === item.property
    );

    return duplicateIndex === index;
  });

  return segmentFormatter(segmentData);
};

const segmentFormatter = (segmentData) => {
  let groupedEvents = {};
  for (let event of segmentData) {
    if (!groupedEvents[event.eventName]) {
      groupedEvents[event.eventName] = [];
    }
    groupedEvents[event.eventName].push(event);
  }

  let output = [];
  for (let eventName in groupedEvents) {
    let events = groupedEvents[eventName];

    let schema = {
      key: eventName,
      type: "TRACK",
      version: 1,
      jsonSchema: {
        $schema: "http://json-schema.org/draft-07/schema#",
        type: "object",
        labels: {
          cat: events[0].category.toLowerCase(),
        },
        description: events[0].eventDescription,
        properties: {
          context: {},
          traits: {
            type: "object",
          },
          properties: {
            type: "object",
            properties: {},
            required: [],
          },
        },
        required: ["properties"],
      },
    };

    // Add Identify events to schema
    if (!excludeIdentifyEvent) { // TODO check functionality
      let identifyEvents = []; 
      identifyEvents = groupedEvents[eventName].filter((el) => {
        if (/identify/gi.test(el.eventType)) {
          identifyEvents.push(el);
          return true;
        }

        return false
      });

      if (identifyEvents.length > 0) {
        let identifySchema = {
          key: eventName,
          type: "IDENTIFY",
          version: 1,
          jsonSchema: {
            $schema: "http://json-schema.org/draft-07/schema#",
            type: "object",
            labels: {
              cat: events[0].category.toLowerCase(),
            },
            description: events[0].eventDescription,
            properties: {
              context: {},
              traits: {
                type: "object",
              },
              properties: {
                type: "object",
                properties: {},
                required: [],
              },
            },
            required: ["properties"],
          },
        };
        for (let identifyEvent of identifyEvents) {
          let paths = identifyEvent.property.split(".");
          let lastObj = identifySchema.jsonSchema.properties.properties;
          for (let i = 0; i < paths.length; i++) {
            let path = paths[i];
            if (!lastObj.properties[path]) {
              lastObj.properties[path] = {
                description: identifyEvent.propertyDescription,
                id: `/properties/traits/properties/${identifyEvent.property}`,
                type:
                  identifyEvent.type === "Date time" ? "string" : identifyEvent.type,
                properties: {},
                required: [], // Initialize the required array here
              };
              if (identifyEvent.type === "Date time") {
                lastObj.properties[path].format = "date-time";
              }
            }
            if (identifyEvent.required === "R" && i === paths.length - 1) {
              lastObj.required.push(path);
            }
            lastObj = lastObj.properties[path];
          }
        }
        output.push(identifySchema);
      }
    }

    for (let event of events) {
      if (/identify/gi.test(event.eventType)) continue;

      let paths = event.property.split(".");
      let lastObj = schema.jsonSchema.properties.properties;
      for (let i = 0; i < paths.length; i++) {
        let path = paths[i];
        if (path === "metadata") {
          if (!lastObj.properties[path]) {
            lastObj.properties[path] = {
              description: event.propertyDescription,
              id: `/properties/properties/properties/${event.property}`,
              type: "object",
              properties: {},
              required: [], // Initialize the required array here
            };
          }
          lastObj = lastObj.properties[path];
          continue;
        }
        if (!lastObj.properties[path]) {
          lastObj.properties[path] = {
            description: event.propertyDescription,
            id: `/properties/properties/properties/${event.property}`,
            type: event.type === "Date time" ? "string" : event.type,
            properties: {},
            required: [], // Initialize the required array here
          };
          if (event.type === "Date time") {
            lastObj.properties[path].format = "date-time";
          }
        }
        if (event.required === "R" && i === paths.length - 1) {
          lastObj.required.push(path);
        }
        lastObj = lastObj.properties[path];
      }
    }
    output.push(schema);
  }
  return output;
};
