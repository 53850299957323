export const validateFigmaInput = (figmaInput) => {
  figmaInput = JSON.parse(figmaInput);

  // Check if figmaInput is an object

  if (typeof figmaInput !== "object" || Array.isArray(figmaInput)) {
    return false;
  }

  // Check if all values are strings
  const allValuesAreStrings = Object.values(figmaInput).every(
    (value) => typeof value === "string"
  );

  return allValuesAreStrings;
};

export const validateSpreadsheetProps = (spreadsheetProps) => {
  spreadsheetProps = JSON.parse(spreadsheetProps);

  // Check if spreadsheetProps is an array
  if (!Array.isArray(spreadsheetProps)) {
    return false;
  }

  // Define required properties
  const requiredProperties = [
    "Property Name",
    "Property Data Type",
    "Property Description",
    "Required",
    "Sample Values",
  ];

  // Check each object in the array
  for (const prop of spreadsheetProps) {
    // Check if all required properties exist
    const hasAllProperties = requiredProperties.every((property) =>
      prop.hasOwnProperty(property)
    );
    if (!hasAllProperties) {
      return false;
    }

    // Check if data types are valid (assuming that the values of "Property Data Type" are comma-separated)
    const dataTypes = prop["Property Data Type"].split(", ");
    const validDataTypes = dataTypes.every(
      (type) =>
        ["string", "number", "boolean", "object", "null", "Date time", "any"].includes(type)
    );
    if (!validDataTypes) {
      return false;
    }
  }

  return true;
};
