import { figmaReader } from "./figma"
import { checkUndefinedProperties, openaiRequest } from "./openai";
import { openaiResponseConverter } from "./openaiResponseConverter";
import { segmentConverter } from "./segment";
import { spreadsheetConverter } from "./spreadsheet";
import { validateFigmaInput, validateSpreadsheetProps } from "./validation";

export const Converter = async (figmaInput, spreadsheetProps) => {
  const isFigmaInputValid = validateFigmaInput(figmaInput);
  const isSpreadsheetPropsValid = validateSpreadsheetProps(spreadsheetProps);

  console.log('Figma validation:', isFigmaInputValid);
  console.log('Spreadsheet validation:', isSpreadsheetPropsValid);

  if (!isFigmaInputValid || !isSpreadsheetPropsValid) {
    throw new Error("Invalid input data");
  }

  const [figmaData, platform] = figmaReader(figmaInput);
  console.log(figmaData, '<<<<<<<<< figma data');
  const spreadsheetData = spreadsheetConverter(figmaData, platform);
  console.log(spreadsheetData, '<<<<<<<<< spreadsheet data');
  const segmentData = segmentConverter(figmaData, spreadsheetProps);
  console.log(segmentData, '<<<<<<<<< segment data');

  const undefinedProperties = checkUndefinedProperties(segmentData);

  console.log(undefinedProperties, '!!! Undefined Properties !!!');

  if (undefinedProperties.length > 0) {
    try {
      const response = await openaiRequest({ undefinedProperties });
      console.log(response, 'response');
      const [newProperties, newTrackPageProperties, newIdentifyProperties] = openaiResponseConverter(response, figmaData);
      console.log(newProperties, newTrackPageProperties, newIdentifyProperties, 'props');
      const newSpreadsheetProps = [...JSON.parse(spreadsheetProps), ...newProperties];
      console.log(newSpreadsheetProps, 'new props');
      const newSegmentData = segmentConverter(figmaData, JSON.stringify(newSpreadsheetProps));
      console.log(newSegmentData, 'new segment');
      return [spreadsheetData, newSegmentData, newProperties, newTrackPageProperties, newIdentifyProperties];
    } catch (error) {
      console.log(error);
      throw new Error('Failed to make the openaiRequest.');
    }
  } else {
    return [spreadsheetData, segmentData];
  }
};

// const response = 'onboarding_stage;string;The stage of the onboarding process;registration';

// const response = 'username_test;string;The username of the user.;user123\n' +
// 'balance_amount_test;number;The amount of the balance;1000\n' +
// 'member_count_test;number;The count of the member;5'

// const response = `path;string;The path of the page.;home
// language;string;The preferred language of the user.;en
// language;string;The preferred language of the user.;en
// payment_currency_option;string;The selected currency option for payment.;USD
// utm_source;string;The source of the UTM parameter.;google
// utm_medium;string;The medium of the UTM parameter.;email
// query;string;The query parameter in the URL.;example_query
// query;string;The query parameter in the URL.;example_query
// job_department;string;The department of the job.;sales
// job_location;string;The location of the job.;New York
// job_contract_type;string;The contract type of the job.;full-time
// contract_type;string;The type of contract for a product or service.;monthly
// department;string;The department of the user.;marketing
// location;string;The location of the user.;Los Angeles
// job_title;string;The title of the job.;sales manager
// country;string;The country of the user.;United States
// account_type;string;The type of account for a user.;business
// product_solutions;string;The solutions offered by a product.;solution_123
// faq_question;string;The question in the FAQ section.;how to contact customer support
// marketing_consent;string;The consent for receiving marketing emails.;true
// email;string;The email address of the user.;example@example.com
// first_name;string;The first name of the user.;John
// last_name;string;The last name of the user.;Doe
// work_email;string;The work email of the user.;johndoe@example.com
// mobile_phone_number;string;The mobile phone number of the user.;1234567890
// moving_money_purpose_type;string;The purpose for transferring money.;business
// plan;string;The selected plan for a service or product.;premium
// from;string;The starting point of the range.;100
// to;string;The ending point of the range.;1000
// amount_from;string;The minimum amount in a range.;5000
// amount_to;string;The maximum amount in a range.;10000
// country_type;string;The type of country.;domestic
// currency_type;string;The type of currency.;EUR
// navbar_item_name;string;The name of the navigation bar item.;about us`

// const response = `utm_source;string;The source of the UTM parameter.;google
// utm_medium;string;The medium of the UTM parameter.;email`

// const response = `name; string; The name of the user; John
// age; number; The age of the user; 25
// email; string; The email address of the user; john@example.com`