import axios from 'axios';

export const pushToSegment = async ({ segmentData, access_token, trackingPlanID }) => {
  try {
    const baseUrl = 'https://sigma.seirei.site/segment/';
    const { data } = await axios.patch(`${baseUrl}${trackingPlanID}`, segmentData, {
      headers: {
        access_token: access_token
      }
    })

    return data;
  } catch (error) {
    throw error;
  }
}