import React, { useState } from 'react';
import { pushToSegment } from '../lib/pushToSegment';
import { failureAlert, successAlert } from '../helpers/sweetalert';

const SegmentModal = ({ onCloseModal, convertedData }) => {
  const [credentials, setCredentials] = useState({
    access_token: '',
    trackingPlanID: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (credentials.access_token === '') {
      failureAlert('Oops..', 'Access token is required');
      return true;
    }

    if (credentials.trackingPlanID === '') {
      failureAlert('Oops..', 'Tracking Plan ID is required');
      return true;
    }

    pushToSegment({
      segmentData: convertedData.segmentData,
      access_token: credentials.access_token,
      trackingPlanID: credentials.trackingPlanID
    })
      .then(response => {
        successAlert('Your segment protocols has been updated!');
        onCloseModal();
      })
      .catch(error => {
        failureAlert(error.response.data.message.type, error.response.data.message.message);
      })
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center z-50">
      <div className="bg-white p-6 w-1/3 rounded shadow-lg">
        <h3 className="font-bold text-xl mb-5">Enter the Segment Credentials</h3>
        <form onSubmit={handleSubmit}>
          <p className="py-4">Access Token</p>
          <input
            type="text"
            name="access_token"
            value={credentials.access_token}
            onChange={handleInputChange}
            placeholder="Type here"
            className="input input-bordered w-full"
            autoComplete='off'
          />
          <p className="py-4">Tracking Plan ID</p>
          <input
            type="text"
            name="trackingPlanID"
            value={credentials.trackingPlanID}
            onChange={handleInputChange}
            placeholder="Type here"
            className="input input-bordered w-full"
            autoComplete='off'
          />
          <div className="modal-action">
            <button type="submit" className="btn btn-neutral">
              Submit
            </button>
            <button className="btn btn-neutral" onClick={onCloseModal}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SegmentModal;
