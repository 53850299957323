import axios from 'axios';

export const checkUndefinedProperties = (arr) => {
  const result = [];
  
  for (const obj of arr) {
    const properties = obj.jsonSchema.properties.properties.properties;
    
    for (const key of Object.keys(properties)) {
      if (!properties[key].description || !properties[key].type) {
        if (key.match(/utm/ig) === null) {
          const duplicatedKey = result.find(el => el === key);
          if (!duplicatedKey) result.push(key);
        }
      }
    }
  }
  
  return result;
}

export const openaiRequest = async (payload) => {
  try {
    const baseUrl = 'https://sigma.seirei.site/openai'
    const { data } = await axios.post(baseUrl, payload);

    return data;
  } catch (error) {
    throw error;
  }
}