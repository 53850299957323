import FileInput from "./components/FileInput";

function App() {
  return (
    <div className="App min-h-screen bg-gray-200 flex flex-col justify-center items-center font-medium">
      <div
        className="card w-1/2 bg-base-100 shadow-xl"
        style={{ minHeight: "93vh" }}
      >
        <figure className="px-10 pt-10 flex flex-col justify-center">
          <img
            src="https://assets-global.website-files.com/6305f1de7920f55a3f32a9f2/6305f1de7920f5a35c32aa09_Logo%20Devhaus.svg"
            alt="Devhaus Logo"
            className="w-60 my-7"
          />
          <h2 className="card-title text-2xl">Event Tracking Plan Converter</h2>
        </figure>
        <div className="mt-10 flex justify-center">
          <img
            src="https://cdn.discordapp.com/attachments/1129262326881726514/1129265115397623918/image.png"
            alt="App Icon"
            className="w-60"
          />
        </div>
        <div className="card-body">
          <FileInput />
        </div>
      </div>
    </div>
  );
}

export default App;
