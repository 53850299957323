export const spreadsheetConverter = (figmaData, platform) => {
  const figmaLink = 'view here';

  const filteredData = figmaData.filter((el, index, self) => {
    const duplicateIndex = self.findIndex(
      (otherEl) =>
        el.eventName === otherEl.eventName &&
        el.eventDescription === otherEl.eventDescription &&
        el.property === otherEl.property
    );

    return duplicateIndex === -1 || index === duplicateIndex;
  });

  const trackData = [];
  const identifyData = [];
  const pageData = [];
  const tempIdentifyData = [];

  const spreadsheetData = filteredData.map(el => {
    let desc = el.eventDescription;

    if (el.eventPath === '') {
      const matchingElement = filteredData.find(
        (otherEl) =>
          el.eventName === otherEl.eventName &&
          otherEl.eventPath !== ''
      );
    
      if (matchingElement) {
        el.eventPath = matchingElement.eventPath;
      }
    }

    const match = desc.match(/\busers?\b/gi);
    if (match) {
      const index = desc.indexOf(match[0]);
      desc = desc.slice(index).trim();
      desc = desc.charAt(0).toUpperCase() + desc.slice(1);
    } else {
      desc = el.whenToFire;
    }

    const transformedData = {
      'Event Name': el.eventName,
      'Page Path': el.eventPath,
      'When to Fire?': el.eventDescription,
      'Event Description': desc || el.eventDescription,
      'Event Category': el.category,
      'Figma Link': figmaLink,
      'Platform': platform,
      'Property Name': /utm/ig.test(el.property) ? `metadata.${el.property}` : el.property,
    };

    if (el.eventType.match(/track/i)) {
      trackData.push(transformedData);
    }

    if (el.eventType.match(/identify/i)) {
      identifyData.push(transformedData);
      tempIdentifyData.push({
        'Track Event Name': el.eventName,
        'Identity Property Name': el.property
      });
    }
  
    if (el.eventType.match(/page/i)) {
      pageData.push(transformedData);
    }

    return transformedData;
  });

  const additionalProperties = [
    'annonymous_id',
    'asn',
    'city',
    'continent_code',
    'country',
    'country_area',
    'country_calling_code',
    'country_capital',
    'country_code',
    'country_code_iso3',
    'country_name',
    'country_population',
    'country_tld',
    'currency',
    'currency_name',
    'in_eu',
    'ip',
    'languages',
    'latitude',
    'longitude',
    'network',
    'network_provider',
    'postal',
    'region',
    'timezone',
    'utc_offset',
    'version',
  ]

  const newIdentifyData = [];

  tempIdentifyData.forEach((el, index) => {
    newIdentifyData.push(el);
    if (!tempIdentifyData[index + 1] || tempIdentifyData[index + 1]['Track Event Name'] !== el['Track Event Name']) {
      additionalProperties.forEach(prop => {
        newIdentifyData.push({
          'Track Event Name': el['Track Event Name'],
          'Identity Property Name': prop
        });
      })
    }
  })

  return {
    full: spreadsheetData,
    track: trackData,
    identify: identifyData,
    page: pageData,
    identifyInput: newIdentifyData
  }
};
