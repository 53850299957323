import { useState, useRef } from "react";
import { Converter } from "../lib/converter";
import SegmentModal from "./SegmentModal";
import { failureAlert, openAIAlert } from "../helpers/sweetalert";

const FileInput = () => {
  const [selectedFigmaFile, setSelectedFigmaFile] = useState(null);
  const [selectedSpreadsheetFile, setSelectedSpreadsheetFile] = useState(null);
  const [convertedData, setConvertedData] = useState(null);
  const [selectedOption, setSelectedOption] = useState("segmentData"); // New state for the selected option
  const [isModalOpen, setIsModalOpen] = useState(false);
  const figmaFileInputRef = useRef(null);
  const spreadsheetFileInputRef = useRef(null);
  const [newProperties, setNewProperties] = useState(null);
  const [newTrackPageProperties, setNewTrackPageProperties] = useState(null);
  const [newIdentifyProperties, setNewIdentifyProperties] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFigmaFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "application/json") {
        setSelectedFigmaFile(file);
      } else {
        failureAlert('Oops..', 'Please select a JSON file for Figma data.');
        figmaFileInputRef.current.value = ""; // Reset the value of the Figma file input
      }
    }
  };

  const handleSpreadsheetFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === "application/json") {
        setSelectedSpreadsheetFile(file);
      } else {
        failureAlert('Oops..', 'Please select a JSON file for Spreadsheet props.');
        spreadsheetFileInputRef.current.value = ""; // Reset the value of the Spreadsheet file input
      }
    }
  };

  const handleConvert = () => {
    if (selectedFigmaFile && selectedSpreadsheetFile) {
      setIsLoading(true);
      const figmaReader = new FileReader();
      figmaReader.onload = () => {
        const figmaFileContent = figmaReader.result;
  
        const spreadsheetReader = new FileReader();
        spreadsheetReader.onload = async () => {
          const spreadsheetFileContent = spreadsheetReader.result;
  
          try {
            const [spreadsheetData, segmentData, newProperties, newTrackPageProperties, newIdentifyProperties] = await Converter(figmaFileContent, spreadsheetFileContent);

            if (newProperties) {
              setNewProperties([...newTrackPageProperties, ...newIdentifyProperties]);
              if (newTrackPageProperties.length > 0) setNewTrackPageProperties(newTrackPageProperties);
              if (newIdentifyProperties.length > 0) setNewIdentifyProperties(newIdentifyProperties);
              openAIAlert(setSelectedOption);
            }
  
            setConvertedData({
              spreadsheetData,
              segmentData,
            });

            setIsLoading(false);
          } catch (error) {
            console.log(error);
            failureAlert("Oops..", "Something went wrong");
            setIsLoading(false);
          }
        };
        spreadsheetReader.readAsText(selectedSpreadsheetFile);
      };
      figmaReader.readAsText(selectedFigmaFile);
    } else {
      failureAlert("Oops..", "Please upload both Figma and Spreadsheet files.");
    }
  };
  
  const download = (type, data, fileName) => {
    const blob = new Blob([data], { type });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
  }

  const handleDownload = () => {
    let jsonData, fileName, jsonData2, fileName2;

    if (selectedOption === "spreadsheetDataFull" && convertedData && convertedData.spreadsheetData.full) {
      jsonData = JSON.stringify(convertedData.spreadsheetData.full, null, 2);
      fileName = "spreadsheet_track_input_data.json";
      jsonData2 = JSON.stringify(convertedData.spreadsheetData.identifyInput, null, 2);
      fileName2 = "spreadsheet_identify_input_data.json";
    } else if (selectedOption === "spreadsheetDataTrack" && convertedData && convertedData.spreadsheetData.track) {
      jsonData = JSON.stringify(convertedData.spreadsheetData.track, null, 2);
      fileName = "spreadsheet_data_track.json";
    } else if (selectedOption === "spreadsheetDataIdentify" && convertedData && convertedData.spreadsheetData.identify) {
      jsonData = JSON.stringify(convertedData.spreadsheetData.identify, null, 2);
      fileName = "spreadsheet_data_identify.json";
    } else if (selectedOption === "spreadsheetDataPage" && convertedData && convertedData.spreadsheetData.page) {
      jsonData = JSON.stringify(convertedData.spreadsheetData.page, null, 2);
      fileName = "spreadsheet_data_page.json";
    } else if (selectedOption === "segmentData" && convertedData && convertedData.segmentData) {
      jsonData = JSON.stringify(convertedData.segmentData, null, 2);
      fileName = "segment_data.json";
    } else if (selectedOption === "IdentifyInput" && convertedData && convertedData.spreadsheetData.identifyInput) {
      jsonData = JSON.stringify(convertedData.spreadsheetData.identifyInput, null, 2);
      fileName = "spreadsheet_identify_input_data.json";
    } else if (selectedOption === "newProperties" && newProperties) {
      if (newTrackPageProperties) {
        jsonData = JSON.stringify(newTrackPageProperties, null, 2);
        fileName = "new_track_input_properties.json";
      }
      if (newIdentifyProperties) {
        jsonData2 = JSON.stringify(newIdentifyProperties, null, 2);
        fileName2 = "new_identify_input_properties.json";
      }
    }

    if (jsonData) download("application/json", jsonData, fileName);
    if (jsonData2) download("application/json", jsonData2, fileName2);
  };

  const handleDownloadCSV = () => {
    if (convertedData && convertedData.spreadsheetData) {
      let jsonData, fileName, jsonData2, fileName2;

      if (selectedOption === 'spreadsheetDataFull') {
        jsonData = JSON.stringify(convertedData.spreadsheetData.full, null, 2);
        fileName = "spreadsheet_track_input_data.csv";
        jsonData2 = JSON.stringify(convertedData.spreadsheetData.identifyInput, null, 2);
        fileName2 = "spreadsheet_identify_input_data.csv";
      } else if(selectedOption === 'spreadsheetDataTrack') {
        jsonData = JSON.stringify(convertedData.spreadsheetData.track, null, 2);
        fileName = 'spreadsheet_data_track.csv';
      } else if(selectedOption === 'spreadsheetDataIdentify') {
        jsonData = JSON.stringify(convertedData.spreadsheetData.identify, null, 2);
        fileName = 'spreadsheet_data_identify.csv';
      } else if(selectedOption === 'spreadsheetDataPage') {
        jsonData = JSON.stringify(convertedData.spreadsheetData.page, null, 2);
        fileName = 'spreadsheet_data_page.csv';
      } else if(selectedOption === 'IdentifyInput') {
        jsonData = JSON.stringify(convertedData.spreadsheetData.identifyInput, null, 2);
        fileName = 'spreadsheet_identify_input_data.csv';
      } else if (selectedOption === "newProperties" && newProperties) {
        if (newTrackPageProperties) {
          jsonData = JSON.stringify(newTrackPageProperties, null, 2);
          fileName = "new_track_and_page_properties.csv";
        }
        if (newIdentifyProperties) {
          jsonData2 = JSON.stringify(newIdentifyProperties, null, 2);
          fileName2 = "new_identify_properties.csv";
        }
      }
      
      if (jsonData) {
        const csvData = convertJsonToCsv(jsonData);
        download("text/csv", csvData, fileName);
      }
      if (jsonData2) {
        const csvData = convertJsonToCsv(jsonData2);
        download("text/csv", csvData, fileName2);
      }
    }
  };

  const convertJsonToCsv = (jsonData) => {
    const data = JSON.parse(jsonData);

    if (!Array.isArray(data) || data.length === 0) {
      return "";
    }

    const header = Object.keys(data[0]);

    const rows = data.map((item) => header.map((key) => {
      const value = item[key];
      return value.includes(",") ? `"${value}"` : value;
    }));

    const csvContent =
      header.join(",") + "\n" + rows.map((row) => row.join(",")).join("\n");

    return csvContent;
  };

  const handleBack = () => {
    setConvertedData(null);
    setSelectedOption("segmentData");
    setSelectedFigmaFile(null);
    setSelectedSpreadsheetFile(null);

    if (figmaFileInputRef.current) {
      figmaFileInputRef.current.value = "";
    }

    if (spreadsheetFileInputRef.current) {
      spreadsheetFileInputRef.current.value = "";
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };  

  return (
    <div className="mb-5">
      {
        isLoading ? <div className="flex justify-center items-center mt-24">
          <span className="loading loading-spinner loading-lg"></span>
        </div> : <>
          {!convertedData && (
            <div className="flex mt-10 justify-between">
              <div>
                <input
                  type="file"
                  className="file-input file-input-bordered w-full max-w-xs"
                  accept=".json"
                  onChange={handleFigmaFileChange}
                  ref={figmaFileInputRef} // Set the ref for accessing the Figma file input element
                />
                <p className="text-slate-500 text-sm mt-1">Select your Figma data (JSON file)</p>
              </div>
              <div>
                <input
                  type="file"
                  className="file-input file-input-bordered w-full max-w-xs"
                  accept=".json"
                  onChange={handleSpreadsheetFileChange}
                  ref={spreadsheetFileInputRef} // Set the ref for accessing the Spreadsheet file input element
                />
                <p className="text-slate-500 text-sm mt-1">Select your Spreadsheet props (JSON file)</p>
              </div>
            </div>
          )}

          {!convertedData && (
            <div className="flex justify-center mt-10">
              <button className="btn btn-neutral px-10 font-bold" onClick={handleConvert}>
                Convert
              </button>
            </div>
          )}

          {convertedData && (
            <div className="mt-5">
              <div className="flex justify-between">
                <div className="flex gap-3">
                  <select
                    className="select select-bordered select-neutral w-full max-w-xs"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="segmentData">Segment Data</option>
                    <option value="spreadsheetDataFull">Spreadsheet Data (Full)</option>
                    <option value="spreadsheetDataTrack">Spreadsheet Data (Track)</option>
                    <option value="spreadsheetDataIdentify">Spreadsheet Data (Identify)</option>
                    <option value="spreadsheetDataPage">Spreadsheet Data (Page)</option>
                    <option value="IdentifyInput">Spreadsheet Data (Identify Input)</option>
                    <option value="newProperties">New Properties (AI Generated)</option>
                  </select>
                  {(selectedOption === "spreadsheetDataFull" ||
                  selectedOption === "spreadsheetDataTrack" ||
                  selectedOption === "spreadsheetDataIdentify" ||
                  selectedOption === "spreadsheetDataPage" ||
                  selectedOption === "IdentifyInput" ||
                  selectedOption === "newProperties") && (
                    <button className="btn btn-neutral" onClick={handleDownloadCSV}>
                      Download CSV
                    </button>
                  )}
                  {selectedOption === "segmentData" && (
                    <button className="btn btn-neutral" onClick={handleOpenModal}>
                      Push to Segment
                    </button>
                  )}
                  <button className="btn btn-neutral" onClick={handleDownload}>
                    Download JSON
                  </button>
                </div>
                <button className="btn btn-neutral px-8" onClick={handleBack}>
                  Back
                </button>
              </div>
              {selectedOption === "segmentData" && convertedData.segmentData && (
                <div className="mt-3 border rounded p-4 bg-white max-h-40 overflow-y-auto">
                  <pre>{JSON.stringify(convertedData.segmentData, null, 2)}</pre>
                </div>
              )}
              {selectedOption === "spreadsheetDataFull" && convertedData.spreadsheetData.full && (
                <div className="mt-3 border rounded p-4 bg-white max-h-40 overflow-y-auto">
                  <pre>{JSON.stringify(convertedData.spreadsheetData.full, null, 2)}</pre>
                </div>
              )}
              {selectedOption === "spreadsheetDataTrack" && convertedData.spreadsheetData.track && (
                <div className="mt-3 border rounded p-4 bg-white max-h-40 overflow-y-auto">
                  <pre>{JSON.stringify(convertedData.spreadsheetData.track, null, 2)}</pre>
                </div>
              )}
              {selectedOption === "spreadsheetDataPage" && convertedData.spreadsheetData.page && (
                <div className="mt-3 border rounded p-4 bg-white max-h-40 overflow-y-auto">
                  <pre>{JSON.stringify(convertedData.spreadsheetData.page, null, 2)}</pre>
                </div>
              )}
              {selectedOption === "spreadsheetDataIdentify" && convertedData.spreadsheetData.identify && (
                <div className="mt-3 border rounded p-4 bg-white max-h-40 overflow-y-auto">
                  <pre>{JSON.stringify(convertedData.spreadsheetData.identify, null, 2)}</pre>
                </div>
              )}
              {selectedOption === "IdentifyInput" && convertedData.spreadsheetData.identifyInput && (
                <div className="mt-3 border rounded p-4 bg-white max-h-40 overflow-y-auto">
                  <pre>{JSON.stringify(convertedData.spreadsheetData.identifyInput, null, 2)}</pre>
                </div>
              )}
              {selectedOption === "newProperties" && newProperties && (
                <div className="mt-3 border rounded p-4 bg-white max-h-40 overflow-y-auto">
                  <pre>{JSON.stringify(newProperties, null, 2)}</pre>
                </div>
              )}
            </div>
          )}

          {isModalOpen && <SegmentModal onCloseModal={handleCloseModal} convertedData={convertedData}/>}
        </>
      }
    </div>
  );
};

export default FileInput;
