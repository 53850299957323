export const figmaReader = (input) => {
  let platform = '';
  const filteredData = Object.entries(JSON.parse(input)).map(([key, value]) => {
    const platformValue = value.split('Platform: ');

    if (platformValue.length === 2) {
      platform = platformValue[1];
    } 

    if (/Description:\s/i.test(value)) {
      const descriptionRegex = /Description:\s*([\s\S]+?)\s*Event Name:/i;
      const eventNameRegex = /Event Name:\s*“(.*?)”\s*Event type:/i;
      const eventTypeRegex = /Event Type:\s*([\s\S]+?)\s*Event Category:/i;
      const eventCategoryRegex = /Event Category:\s*([\s\S]+?)\s*Event Properties:/i;
      const eventPropertiesRegex = /Event Properties:\s*([\s\S]+)/i;

      const descriptionMatch = value.match(descriptionRegex);
      const eventNameMatch = value.match(eventNameRegex);
      const eventTypeMatch = value.match(eventTypeRegex);
      const eventCategoryMatch = value.match(eventCategoryRegex);
      const eventPropertiesMatch = value.match(eventPropertiesRegex);

      const description = descriptionMatch ? descriptionMatch[1].trim() : null;
      const eventName = eventNameMatch ? eventNameMatch[1].trim() : null;
      const eventType = eventTypeMatch ? eventTypeMatch[1].trim() : null;
      const eventCategory = eventCategoryMatch ? eventCategoryMatch[1].trim() : null;
      const eventPropertiesString = eventPropertiesMatch ? eventPropertiesMatch[1].trim() : null;

      // const description = value.split('Description: ')[1].split(' Event Name:')[0].trim();
      // const eventName = value.split('Event Name: ')[1].split(' Event type:')[0].trim().slice(1, -1);
      // const eventType = value.split('Event type: ')[1].split(' Event Category:')[0].trim();
      // const eventCategory = value.split('Event Category: ')[1].split(' Event Properties:')[0].trim();
      // const eventPropertiesString = value.split('Event Properties: ')[1].trim();

      const eventProperties = eventPropertiesString.split(/\s+(?=\w+:)/).reduce((props, pair) => {
        const [key, ...values] = pair.split(':');
        if (values.length > 0) {
          const value = values.join(':').trim();
          props[key.trim()] = value;
        } else {
          props[key.trim()] = '';
        }
        return props;
      }, {});
  
      if (eventProperties.metadata) {
        const metadata = {};
        eventProperties.metadata.split(' ').forEach(pair => {
          const [key, value] = pair.split(':');
          metadata[key] = value || 'string';
        });
        eventProperties.metadata = metadata;
      }
  
      const result = [];
      for (const property in eventProperties) {
        if (property === 'https' || property === 'http') continue;
        if (property === 'Platform') continue;
        if (property !== 'metadata' && property !== 'utm_source' && property !== 'utm_medium' && property !== 'utm_campaign') {
          result.push({
            eventName: eventName,
            eventDescription: description,
            category: eventCategory,
            property: property,
            eventPath: eventProperties.path || '',
            eventType: eventType
          });
        }
        if (property === 'metadata') {
          result.push({
            eventName: eventName,
            eventDescription: description,
            category: eventCategory,
            property: 'metadata.utm_source',
            eventPath: eventProperties.path || '',
            eventType: eventType
          }, {
            eventName: eventName,
            eventDescription: description,
            category: eventCategory,
            property: 'metadata.utm_medium',
            eventPath: eventProperties.path || '',
            eventType: eventType
          }, {
            eventName: eventName,
            eventDescription: description,
            category: eventCategory,
            property: 'metadata.utm_campaign',
            eventPath: eventProperties.path || '',
            eventType: eventType
          });
        }
      }
  
      return result;
    }
  
    return null;
  }).filter(Boolean);
  
  const result = filteredData.flat();
  return [result, platform];
}