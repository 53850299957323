export const openaiResponseConverter = (openaiResponse, figmaData) => {
  const newProperties = openaiResponse.split('\n').map(el => {
    const arrayProp = el.split(';');

    if (arrayProp[0].match(/utm/ig)) return null;
    
    return {
      'Property Name': arrayProp[0] || '',
      'Property Data Type': arrayProp[1] || '',
      'Property Description': arrayProp[2] || '',
      'Required': 'R',
      'Sample Values': arrayProp[3] || '',
    }
  }).filter(Boolean);

  const filteredNewProperties = newProperties.filter((item, index, self) => {
    const duplicateIndex = self.findIndex(
      (otherItem) =>
        otherItem['Property Name'] === item['Property Name'] &&
        otherItem['Property Data Type'] === item['Property Data Type'] &&
        otherItem['Property Description'] === item['Property Description'] &&
        otherItem['required'] === item['required'] &&
        otherItem['Sample Values'] === item['Sample Values']
    );

    return duplicateIndex === index;
  });

  const newTrackPageProperties = [];
  const newIdentifyProperties = [];

  filteredNewProperties.forEach(el => {
    const prop = figmaData.find(item => item.property === el['Property Name']);
    if (prop?.eventType === 'Track' || prop?.eventType === 'Page') newTrackPageProperties.push(el);
    if (prop?.eventType === 'Identify') newIdentifyProperties.push(el);
  })

  return [filteredNewProperties, newTrackPageProperties, newIdentifyProperties];
}